<button *ngIf="!href || href === ''" [ngClass]="classes" [disabled]="isDisabled" (click)="type === 'share' ? getShare() : ''">
  <ng-container>
    <img *ngIf="icon && (!href || href === '')" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}">
    <ng-container *ngIf="typeRow === 'doubleRow'; else singleText">
      <span class="double-row-text">
        <span class="text1" [innerHTML]="safeHtml"></span>
        <span class="text2">{{ text2 }}</span>
      </span>
    </ng-container>

    <ng-template #singleText>
      <span [innerHTML]="safeHtml"></span>

    </ng-template>

    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}">
  </ng-container>
</button>

<ng-container *ngIf="target === '_blank'">
  <a *ngIf="href && href !== ''" [target]="target" [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}">

    <ng-container *ngIf="typeRow === 'doubleRow'; else singleText">
      <span class="double-row-text">
        <span class="text1">{{ text }}</span>
        <span class="text2">{{ text2 }}</span>
      </span>
    </ng-container>

    <ng-template #singleText>
      <span>{{ text }}</span>
    </ng-template>

    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}">
  </a>
</ng-container>

<ng-container *ngIf="target !== '_blank'">
  <a *ngIf="href && href !== '' && !href.startsWith('tel:')" 
     [ngClass]="classes" 
     (click)="navigateWithFragment()"
     style="cursor: pointer;">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}">

    <ng-container *ngIf="typeRow === 'doubleRow'; else singleText">
      <span class="double-row-text">
        <span class="text1">{{ text }}</span>
        <span class="text2">{{ text2 }}</span>
      </span>
    </ng-container>

    <ng-template #singleText>
      <span>{{ text }}</span>
    </ng-template>

    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}">
  </a>


  <a *ngIf="href && href !== '' && href.startsWith('tel:')" [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}">

    <ng-container *ngIf="typeRow === 'doubleRow'; else singleText">
      <span class="double-row-text">
        <span class="text1">{{ text }}</span>
        <span class="text2">{{ text2 }}</span>
      </span>
    </ng-container>

    <ng-template #singleText>
      <span>{{ text }}</span>
    </ng-template>

    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}">
  </a>
</ng-container>
