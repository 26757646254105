<div class="key-point-component">
  <Image type="icon" image="{{icon}}" width="56px" height="56px"></Image>
  <div class="key-point-content">
    <TitleAtom text="{{value}}" color="dark" size="heading" weight="semi-bold"></TitleAtom>
    <ParagraphAtom text="{{text}}" color="dark" size="small" weight="medium"></ParagraphAtom>
  </div>
  <div class="key-point-content-mobile">
    <TitleAtom text="{{value}}" color="dark" size="heading" weight="semi-bold"></TitleAtom>
    <ParagraphAtom text="{{text}}" color="dark" size="nano" weight="medium-weight"></ParagraphAtom>
  </div>
</div>
