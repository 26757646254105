<ng-container *ngIf="image !== ''">
  <div class="image-component" [ngClass]="classes">
    <img 
      [attr.width]="getNumericValue(this.windowWidth > 1024 ? width : mobileWidth)" 
      [attr.height]="getNumericValue(this.windowWidth > 1024 ? height : mobileHeight)"
      loading="lazy" 
      [ngClass]="classes" 
      *ngIf="type === 'image'" 
      [src]="'../../../assets/images/' + image + '.png'" 
      [alt]="alt !== '' ? alt : image"
      [ngStyle]="{
        'width': this.windowWidth > 1024 ? width : mobileWidth,
        'height': this.windowWidth > 1024 ? height : mobileHeight || 'auto'
      }"
    >
    <img [attr.width]="getNumericValue(this.windowWidth > 1024 ? width : mobileWidth)" [attr.height]="getNumericValue(this.height ? this.height : 'auto')" loading="lazy" [ngClass]="classes" *ngIf="type === 'icon'" [src]="'../../../assets/images/icons/' + image + '.svg'" alt="{{alt !== '' ? alt : image }}" [ngStyle]="{
    'width': this.windowWidth > 1024 ? width : mobileWidth,
    'height': this.windowWidth > 1024 ? height : mobileHeight || 'auto'
  }">
  </div>
</ng-container>

<ng-container *ngIf="imageExternal !== ''">
  <div class="image-component" [ngClass]="classes">
    <img [attr.width]="getNumericValue(this.windowWidth > 1024 ? width : mobileWidth)" [attr.height]="getNumericValue(this.height ? this.height : 'auto')" loading="lazy" [ngClass]="classes" [src]="imageExternal" alt="{{alt !== '' ? alt : image }}" [ngStyle]="{
      'width': this.windowWidth > 1024 ? width : mobileWidth,
      'height': this.windowWidth > 1024 ? height : mobileHeight || 'auto'
    }">
  </div>
</ng-container>

<ng-container *ngIf="imageCloudinary !== ''">
  <div class="image-component" [ngClass]="classes">
    <img [attr.width]="getNumericValue(this.windowWidth > 1024 ? width : mobileWidth)" [attr.height]="getNumericValue(this.height ? this.height : 'auto')" loading="lazy" [ngClass]="classes" [src]="getCloudinaryUrl()" alt="{{alt !== '' ? alt : image }}"  [ngStyle]="{
      'width': this.windowWidth > 1024 ? width : mobileWidth,
      'height': this.windowWidth > 1024 ? height : mobileHeight || 'auto'
    }">
  </div>
</ng-container>

