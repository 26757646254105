import {Component, Inject, OnInit, HostListener, PLATFORM_ID, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import {CmsMenuType, CmsResponseType} from "../../types/cms.types";
import {CmsService} from "../../services/cms.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'Navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  /** Cms data **/
  public cmsData: CmsMenuType  | null = null;

  /** Temporary CMS data for new API call (Menu B) **/
  public cmsDataTemp: CmsMenuType | null = null;

  /* To check if menu is open */
  public openMenus: boolean[] = [];

  /* To check if navbar is sticky */
  public sticky: boolean = false;

  /* The current url */
  public currentUrl: string = '';

  /* MOBILE DECLARATIONS */
  public menuIsOpen: boolean = false;
  public offersIsOpen: boolean = false;
  public informedIsOpen: boolean = false;
  public aboutIsOpen: boolean = false;

  /** The width and height of the window user */
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    private cms: CmsService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

    // Display the menu
    this.getCmsData();

    // Get the updated data from the CMS
    this.fetchUpdatedMenuData();
  }

  /**
   * Close the navbar links when the user clicks
   * @param event - The click event
   */
  public closeNavbarLinks(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;

    const navbarLinks = element.closest('.navbar-links') as HTMLElement;
    if (navbarLinks) {
      // Add the hidden class to the navbar links
      navbarLinks.classList.add('hidden');
    }
  }

  /**
   * Remove the hidden class from the navbar links
   * @param index - The index of the navbar links
   */
  public removeHiddenClass(index: number): void {
    const navbarLinks = document.querySelectorAll('.navbar-links')[index] as HTMLElement;
    if (navbarLinks) {
      navbarLinks.classList.remove('hidden');
    }
  }

  /**
   * Get the data from the CMS
   */
  private getCmsData(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Get the data from the local storage
      const cachedData = localStorage.getItem('cmsMenuData');
      if (cachedData) {
        this.cmsData = JSON.parse(cachedData);
      }
    }
  }

  /**
   * Call the CMS API to get the updated data
   */
  private fetchUpdatedMenuData(): void {
    this.cms.retrieve(`[[at(document.type, "menu")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        // Store the new data
        this.cmsDataTemp = content.results[0];

        // Update the menu
        if (this.cmsDataTemp) {
          this.updateMenuIfChanged(this.cmsDataTemp);
        }
      });
    });
  }

  /**
   * Update the menu if the data has changed
   * @param newData - The new data to compare
   */
  private updateMenuIfChanged(newData: CmsMenuType): void {
    if (!this.cmsData || JSON.stringify(this.cmsData) !== JSON.stringify(newData)) {
      this.cmsData = newData;
      // Store the new data in the local storage
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('cmsMenuData', JSON.stringify(this.cmsData));
      }
    }
  }

  /**
   * Check scroll for sticky navbar
   */
  @HostListener('window:scroll', ['$event'])
  handleScroll(){
    const windowScroll = window.pageYOffset;

    if (windowScroll >= 40){
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  /* MOBILE MENU */
  public openMenu(): void {
    this.menuIsOpen = true;
    document.body.classList.add('modal-open');
  }

  /**
   * Open the submenu of the menu item
   * @param index - The index of the menu item
   */
  public openMenuItem(index: number): void {
    this.openMenus[index] = !this.openMenus[index];
  }

  /**
   * Check if the submenu is open
   * @param index - The index of the menu item
   */
  public isMenuItemOpen(index: number): boolean {
    return this.openMenus[index];
  }

  /**
   * Handle the click on the main item of the menu
   * @param index - The index of the menu item
   * @param url - The url to navigate to
   */
  public handleMainItemClick(index: number, url: string | null): void {
    if (url) {
      this.router.navigateByUrl(url);
      document.body.classList.remove('modal-open');
    } else {
      this.openMenuItem(index);
    }
  }

  /**
   * Check if the item has a card
   * @param item - The item to check
   */
  public hasCardMethod(item: any): boolean {
    return !!(item.submenu_card_img?.url && item.submenu_card_title && item.submenu_card_btn_link?.url && item.submenu_card_btn_text);
  }

  /**
   * Close the menu
   */
  public closeMenu(): void {
    this.menuIsOpen = false;
    document.body.classList.remove('modal-open');
  }

  public openOffers(): void {
    this.offersIsOpen = !this.offersIsOpen;
  }

  public openInformed(): void {
    this.informedIsOpen = !this.informedIsOpen;
  }

  public openAbout(): void {
    this.aboutIsOpen = !this.aboutIsOpen;
  }
}
