import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'Image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  /** The internal image path */
  @Input() image: string = '';

  /** The external image path */
  @Input() imageExternal: string = '';

  /** The cloudinary image path */
  @Input() imageCloudinary: string = '';

  /** The alt of the image */
  @Input() alt: string = '';

  /** The type of image */
  @Input() type: string = '';

  /** The width for image */
  @Input() width: string = '';

  /** The height for image */
  @Input() height: string = '';

  /** The width for mobile version */
  @Input() mobileWidth: string = '';

  /** The height for mobile version */
  @Input() mobileHeight: string = '';

  /** The border radius of image */
  @Input() borderRadius: string = '';

  /* The size of image */
  @Input() size: string = '';

  /** The width and height of the window user */
  public windowWidth = 2000;

  public get classes(): Array<string> {
    return [`image img-${this.borderRadius} ${this.size}`]
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
    }
  }

  /**
   * Get the cloudinary url
   * @returns The cloudinary url
   */
  public getCloudinaryUrl(): string {
    const baseUrl = "https://res.cloudinary.com/monabee/image/upload";
    const width = this.width !== '' ? `w_${Math.round(Number(this.width.replace('px', '')) * 1.5)}` : ''
    return `${baseUrl}/${width}/q_auto/v1685418510/live/${this.imageCloudinary}`;
  }

  /**
   * Get the numeric value of the input
   * @param value - The value to convert to number
   * @returns The numeric value of the input
   */
  public getNumericValue(value: string): number | null {
    if (!value || value === 'auto') return null;
    return parseInt(value.replace('px', ''));
  }

}

