import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'ButtonAtom',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  /** Text in Button */
  @Input() text: string = '';


  /** Text2 in Button */
  @Input() text2: string = '';

  @Input() typeRow: string = 'singleRow'; // 'singleRow' ou 'doubleRow'

  /** Image for button */
  @Input() icon: string = '';

  /** Image on the right of the button */
  @Input() iconRight: string = '';

  /** Style of button: clear, stroked, basic */
  @Input() style: string = '';

  /** Size of the button : small, default */
  @Input() size: string = '';

  /** Size of the button : small, default */
  @Input() sizeText: string = 'default';

  /** Color of element: light, dark, yellow, blue */
  @Input() color: string = '';

  /** Link to navigate */
  @Input() href: string = '';

  /** Link to navigate on section */
  @Input() fragmentLink: string = '';

  /** Target of the link */
  @Input() target: string = '_self';

  /** Button is disabled */
  @Input() isDisabled: boolean = false;

  /** Query params of the btn */
  @Input() queryParams: any;

  /** Type of the btn */
  @Input() type: string = '';

  @Input() shareIsHidden = true;

  /** share url */
  @Input() shareUrl: string = '';

  /** share text */
  @Input() shareText: string = 'J’ai trouvé cet article sur Monabee ! ';

  /** Classes for the button */
  public classes: string[] = [];

  safeHtml: SafeHtml | undefined;


  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
  }

  ngOnInit(): void {
    this.classes = this.style !== 'is-link' ? ['button', `${this.style}-${this.color} ${this.size}`, this.icon ? 'has-icon' : ''] : [this.style];
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.text);

  }

  public getShare() {
    this.shareIsHidden = !this.shareIsHidden;

    /* Get the current url */
    this.shareUrl = window.location.href;

  }

  /** Copy to clipboard the url */
  public copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.shareUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  /**
   * Navigate to internal link with fragment handling
   */
  public navigateWithFragment() {
    if (this.href) {
      const [url, anchor] = this.href.split('#');
      
      if (url && anchor) {
        // If URL with anchor
        this.router.navigate([url], { fragment: anchor.trim() }).then(() => {
          this.scrollToAnchorWhenReady(anchor.trim());
        });
      } else if (anchor) {
        // If only anchor (same page)
        this.scrollToAnchorWhenReady(anchor.trim());
      } else {
        // If only URL without anchor
        this.router.navigate([this.href]);
      }
    }
  }

  /**
   * Function to scroll to anchor when the element is ready
   * @param anchor - The anchor to scroll to
   */
  private scrollToAnchorWhenReady(anchor: string) {
    const maxAttempts = 50; // 5 seconds maximum
    let attempts = 0;
    const checkExist = setInterval(() => {
      const element = document.getElementById(anchor);
      if (element || attempts >= maxAttempts) {
        clearInterval(checkExist);
        if (element) {
          this.viewportScroller.scrollToAnchor(anchor);
        }
      }
      attempts++;
    }, 100);
  }
}
