import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { StorageService } from "./services/storage.service";
import { Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private storage: StorageService,
        private meta: Meta
    ) {
    }

    ngOnInit() {
        // Execute code only on browser
        if (isPlatformBrowser(this.platformId)) {
            // Delay to be sure to get the query params
            setTimeout(() => {
                this.storage.setValue('gclid', this.route.snapshot.queryParamMap.get('gclid') || '');
                this.storage.setValue('fbclid', this.route.snapshot.queryParamMap.get('fbclid') || '');
                this.storage.setValue('utm_source', this.route.snapshot.queryParamMap.get('utm_source') || '');
                this.storage.setValue('utm_campaign', this.route.snapshot.queryParamMap.get('utm_campaign') || '');
                this.storage.setValue('utm_medium', this.route.snapshot.queryParamMap.get('utm_medium') || '');
                this.storage.setValue('gad_source', this.route.snapshot.queryParamMap.get('gad_source') || '');
            }, 100);

            // Subscribe to router events to clean up meta tags on navigation
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe(() => {
                // Clean up video meta tags on each navigation
                this.cleanupVideoMetaTags();
            });
        }
    }

    private cleanupVideoMetaTags(): void {
        // Remove all video-related meta tags
        this.meta.removeTag("property='og:video'");
        this.meta.removeTag("property='og:video:secure_url'");
        this.meta.removeTag("property='og:video:type'");
        this.meta.removeTag("property='og:video:width'");
        this.meta.removeTag("property='og:video:height'");
        this.meta.removeTag("property='og:type'");
    }
}
